import { NAME_DIMENSION_CONST, NAME_LABELS, _NAME, NAME_TABLES, SEL_TABLE, FILTER_BASE_KEYS, SEL_FILTERS} from '../pages/forecaster/models/forecaster.constants';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class MultiselectorService {

  constructor(private sessionStorage: SessionStorageService) { }

  // ----- filtering name_tables by entity_list
  getSelectedNameTables(entity_list: string[]): string[][] {
    const self = this;
    const big_table: string[][] = self.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_TABLES];
    const last_column = self.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_LABELS].length;
    const new_big_table: string[][] = big_table.filter(row => self.isItemInArray(entity_list, row[last_column]));
    return new_big_table;
  }

  // ----- getting ids_list from the last column of name_tables or its part
  getEntityList(big_table: string[][]): string[] {
    const name_labels = this.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_LABELS];
    const ids_list: string[] = big_table.map(row => row[name_labels.length]);
    return ids_list;
  }

  // ------ find item in array
  isItemInArray(my_array: string[], item: string): boolean {
    return my_array.length > 0 && my_array.find(x => x === item) !== undefined;
  }

  // ------ service function for getting unique array from array - remove duplicates by 2 possible ways
  getUniqueArray(arr: string[]) {
    // const obj = {};
    // for (let i = 0; i < arr.length; i++) {
    //   const str = arr[i];
    //   obj[str] = true;
    // }
    // return Object.keys(obj);
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // ------ compare 2 rows from the begining to col_index - 1 ( including)
  ifEqualKeysInRow(col_index: number, big_table_row: string[], select_table_row: string[]): boolean {
    for (let j = col_index; j > 0; j--) {
      if (select_table_row[j - 1] !== big_table_row[j - 1]) {
        return false;
      }
    }
    return true;
  }

  ifShortRowInTable(table: string[][], cur_row: string[]) { 
    const new_table = table.filter(row => this.ifEqualKeysInRow(cur_row.length, row, cur_row));
    return new_table.length > 0;
  }

  // ------ check if noone item of array in another array
  ifAtLeastOneItemInArray(source_array: string[], main_array: string[]) {
    const result = false;
    for (let i = 0; i < source_array.length; i++) {
      if (this.isItemInArray(main_array, source_array[i])) {
        return true;
      }
    }
    return false;
  }

  // ------ compare 2 arrays: items of them are equal but can be in different orders
  isTheSameArray2Array(array1: string[], array2: string[]): boolean {
    if (array1.length === 0 && array2.length === 0) {
      return true;
    }
    if (array1.length !== array2.length) {
      return false;
    }
    if (array2.length === 0) {
      return false;
    }
    const new_array = array1.filter(function (item, i) {
      return array2.indexOf(item) !== -1
    });
    return new_array.length === array1.length && new_array.length === array2.length;
  }

  // ------ compare 2 arrays: items of them are equal and they have equal orders
  isEqualArray2Array(array1: string[], array2: string[]): boolean {
    if (array1.length === 0 && array2.length === 0) {
      return true;
    }
    if (array1.length !== array2.length) {
      return false;
    }
    if (array2.length === 0) {
      return false;
    }
    const new_array = array1.filter(function (item, i) {
      return item === array2[i];
    });
    return new_array.length === array1.length && new_array.length === array2.length;
  }

  // ------- check if one array is in table
  isArrayInArrayArray(array_array: string[][], searched_array: string[]): boolean {
    if (array_array === undefined || array_array.length === 0) {
      return false;
    }
    const new_array_array = array_array.filter(array => this.isEqualArray2Array(array, searched_array));
    return new_array_array.length > 0;
  }
  // ------- check if one big_table is equal to another table
  isEqualTable2Table(big_table: string[][], select_table: string[][]) {
    if (big_table.length !== select_table.length) {
      return false;
    }

    for (let i = 0; i < big_table.length; i++) {
      if (!this.isArrayInArrayArray(select_table, big_table[i])) {
        return false;
      }
    }
    return true;
  }

  isEqualObj2Obj(obj1: Object, obj2: Object) { 
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  // ------ for comparing two arrays of strings
  getRowIndexInTable(row: string[], table: string[][]) {
    const self = this;
    if (table && table.length === 0) {
      return -1;
    }

    for (let i = 0; i < table.length; i++) {
      if (self.isEqualArray2Array(row, table[i])) {
        return i;
      }
    }
    return -1;
  }

  getRowByID(entity_id: string, table: string[][]) {
    const last_column = this.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_LABELS].length;
    const res_row = table.filter(row => row[last_column] === entity_id)[0];
    return res_row;
  }

  // ------ functions for getFilterIniItems ( main-data-table) ------------------------------------
  convertTableColumn2UniqueArray(table: string[][], col_index: number) {
    const column_data: string[] = table.map(row => row[col_index]);
    return this.getUniqueArray(column_data);
  }

  // ------------------------ gor Siblings & Filters
  getFilterIniItems(table: string[][], main_selector: string[], filter_base: Object, col_key: string, col_index: number) {
    const filtered_table: string[][] = table.filter(row => this.ifOtherColsMatcheFilterBase(row, main_selector, filter_base, col_key));
    const items = this.convertTableColumn2UniqueArray(filtered_table, col_index);
    return items;
  }

  getFilteredItems(table: string[][], main_selector: string[], filter_base: Object, col_key: string, col_index: number) {
    const filtered_table: string[][] = table.filter(row => this.ifRowMatchesFilterBase(row, main_selector, filter_base, col_key));
    const items = this.convertTableColumn2UniqueArray(filtered_table, col_index);
    return items;
  }

  ifInFilterArray(value: string, filter_array: string[]): boolean {
    if (filter_array.length === 0) {
      return true;
    }
    return this.isItemInArray(filter_array, value);
  }

  ifOtherColsMatcheFilterBase(row: string[], main_selector: string[], filter_base: Object, col_key: string) {
    let rez = true;
    main_selector.forEach((scope_key, j) => {
      if (scope_key !== col_key) {
        const local_rez = this.ifInFilterArray(row[j], filter_base[scope_key]);
        rez = rez && local_rez;
      }
    });
    return rez;
  }

  ifRowMatchesFilterBase(row: string[], main_selector: string[], filter_base: Object, col_key: string) {
    let rez = true;
    main_selector.forEach((scope_key, j) => {
      const local_rez = this.ifInFilterArray(row[j], filter_base[scope_key]);
      rez = rez && local_rez;
    });
    return rez;
  }

  getFilteredBigTable(big_table: string[][], main_selector: string[], filter_base: Object): string[][] {
    // const name_tables = this.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_TABLES];
    const filtered_table: string[][] =
      big_table.filter(row => this.ifRowMatchesFilterBase(row, main_selector, filter_base, ''));
    return filtered_table;
  }

  getLowestLevelSiblings(big_table: string[][], main_selector: string[], entity_id: string): string[] {
    // const name_labels: string[] = this.sessionStorage.get(NAME_DIMENSION_CONST)[NAME_LABELS];
    const current_row: string[] = big_table.filter(row => row[main_selector.length] === entity_id)[0];
    const lowest_level_parent_table: string[][] =
      big_table.filter(row => this.ifEqualKeysInRow(main_selector.length - 1, row, current_row));
    return lowest_level_parent_table.map(row => row[main_selector.length]);
  }
  // --------------------------- for Simulation Screen
  convertString2Array(text: string, separator) {
    return text.split(separator);
  }

  convertArray2String(array: string[], separator) {
    return array.join(separator);
  }

}
